<template>
  <v-card class="basic-modal" elevation="4">
    <v-card-title class="d-flex justify-space-between">
      <h5 class="font-weight-light">
        <v-icon size="24" class="mr-4 text--heading"> $eye </v-icon>
        {{ $t('heading.hosting.emailAddress.modal.show.title') }}
      </h5>
      <v-btn icon x-small @click="$emit('modal-close')">
        <v-icon size="24">$close</v-icon></v-btn
      >
    </v-card-title>

    <div class="tabs">
      <v-tabs v-model="tab">
        <v-tab>{{ $t('heading.hosting.emailAddress.modal.show.tab.ssl') }} <br />({{ $t('general.recommended') }}) </v-tab>
        <v-tab>
          {{ $t('heading.hosting.emailAddress.modal.show.tab.noSsl') }} <br />
          {{ $t('general.settings') }}
        </v-tab>
      </v-tabs>
    </div>

    <v-card-text class="pb-8">
      <v-tabs-items :value="tab">
        <v-tab-item>
          <ul class="details-list">
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.username') }}: <span>{{ details.smtp_username }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.password.key') }}:
              <span>{{ $t('heading.hosting.emailAddress.modal.show.password.value') }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.incomingServer') }}: <span>{{ details.inbox_host }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.imap') }}: <span>{{ details.inbox_port }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.pop') }}: <span>{{ details.inbox_port }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.smtpHost') }}: <span>{{ details.smtp_host }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.smtpPort') }}: <span>{{ details.smtp_port }}</span>
            </li>
          </ul>
        </v-tab-item>
        <v-tab-item>
          <ul class="details-list">
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.username') }}: <span>{{ details.smtp_username }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.password.key') }}:
              <span>{{ $t('heading.hosting.emailAddress.modal.show.password.value') }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.incomingServer') }}: <span>{{ details.inbox_host }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.imap') }}: <span>{{ details.inbox_insecure_port }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.pop') }}: <span>{{ details.inbox_insecure_port }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.smtpHost') }}: <span>{{ details.smtp_host }}</span>
            </li>
            <li>
              {{ $t('heading.hosting.emailAddress.modal.show.smtpPort') }}: <span>{{ details.smtp_insecure_port }}</span>
            </li>
          </ul>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    details: Object,
  },
  data: function () {
    return {
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__actions {
      padding-top: 16px;
    }
    .v-card__title {
      min-height: 96px;
    }
    .v-card__text {
      -webkit-line-clamp: initial;
    }
  }
  .v-card::v-deep {
    .v-card__title {
      flex-wrap: nowrap;
      .v-btn {
        align-self: flex-start;
      }
    }
  }
}

.details-list {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  li {
    display: inline-flex;
    justify-content: space-between;
    color: var(--v-gray-lighten1);
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-semibold;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    span {
      color: var(--v-text-darken2);
      width: 50%;
    }
  }
}

.tabs {
  padding: 0px 32px;
  border-bottom: 1px solid var(--v-gray-lighten3);

  .v-tabs::v-deep {
    max-height: 72px;
    height: 72px;
    .v-tab {
      max-height: 72px;
      height: 72px;
      text-align: left;
      font-weight: $font-weight-bold;
      color: var(--v-gray-lighten1) !important;
      font-size: $font-size-xlg;
      &--active {
        color: var(--v-text-darken2) !important;
      }
    }
    .v-tabs-bar {
      max-height: 72px;
      height: 72px;
    }
  }
}
</style>